<template>
  <div class="article">
    <div class="sale_policy">
      <p>
        用户在库课网校任意客户端，包含但不限于PC端网站、WAP版网站、APP等，选择商品提交订单并支付，库课网校即认为用户已充分理解和同意本协议的全部内容，并签署了本协议，本协议已在用户与本公司之间产生合同法律效力，用户使用本平台服务的全部活动将受到本协议的约束并应承担相应的责任和义务。
      </p>
      <p>售后政策条例如下，请您仔细阅读。</p>
      <h6>•退班退课政策</h6>
      <b>1.退款流程：</b>
      <p>
        在个人中心订单列表点击【申请售后】→等待工作人员电话核实相关信息→系统退款
      </p>
      <br />
      <b
        >2.退款方式：
        <p>由于本网站课程为电子服务虚拟产品，故退款比例遵循如下：</p>
        <p>自付款成功开始算起→t=购买课程时间</p>
      </b>
      <br />
      <table>
        <tbody>
          <tr>
            <td>t&lt;=3小时</td>
            <td>全额</td>
          </tr>
          <tr>
            <td>3小时&lt;t&lt;=24小时</td>
            <td>80%</td>
          </tr>
          <tr>
            <td>24小时&lt;t&lt;=48小时</td>
            <td>50%</td>
          </tr>
          <tr>
            <td>48小时&lt;t&lt;=72小时</td>
            <td>20%</td>
          </tr>
          <tr>
            <td>超过72小时</td>
            <td>不予退款</td>
          </tr>
        </tbody>
      </table>
      <br />
      <p>注：</p>
      <b>
        <p>
          1)退款只支持微信、支付宝等方式购买的网课，通过学习卡充值充值购买不予退款，题库、单课时、激活码购买不支持退款。
        </p>
        <p>
          2)
          购买网课中附送教材的，教材不予退还，退款时将扣除图书全额费用与运费。
        </p>
        <p>
          3)通过苹果设备购买，因苹果平台规则设置问题，无法进行退款，用户请谨慎下单。
        </p>
        <p>4) 退款申请提交后请保持联系方式畅通，我们会尽快与您确认信息。</p>
        <p>5) 参与活动特价（包括但不限于团购、秒杀）购买的课程不予退款</p>
        <p>6) 申请退款后，完成退款流程，金额将在15个工作日内退款至你的账号。</p>
        <p>
          7)二次退款须知：用户首次购买某一课程/套餐后发起退款，适用正常退款规则；二次购买同一课程或者含有该课程的套餐，发起退款后审核期为30个工作日，同时将扣除实际支付金额的30%。
        </p>
        <p>8) 实物商品退款说明：</p>
        <p>
          &nbsp;&nbsp;a.若用户发起退款的商品中，包含实物商品，并且在退款到账前，用户已签收实物商品，那么在实施退款时，用户将承担“自购买至退款”过程中所有物流费用，该费用将从原退款金额中进行扣除（库课网校将实物商品寄给用户的物流费用默认为10元）。若寄回的商品物流费用，用户在寄出时已完成支付，库课网校将不会扣除已支付的物流费用。
        </p>
        <p>
          &nbsp;&nbsp;b.实物商品或包含实物的商品，在库课网校收到实物后，将应退款项原路退至用户的支付账户中。
        </p>
      </b>
      <p>
        附：库课网校视频课程观看包含有效期限，请您购买后在有效期限内进行学习，因个人原因导致课程过期，库课网校不提供退款服务，敬请谅解。
      </p>
      <br />
      <b>•如何变更电话、地址等个人信息？</b>
      <p>
        1、 登录库课网首页：<b
          ><a href="http://www.kuke99.com">http://www.kuke99.com</a></b
        >在页面右侧“用户登陆”输入正确用户名、密码及验证码。
      </p>
      <p>
        2、进入“个人设置”→“收货地址”，在“收货地址”页面修改自己需要变更的内容，变更后保存即可。
      </p>
      <p>3、 如学员想修改登录密码，可以在个人设置-账户密码里进行修改。</p>
      <p>
        4、学员长时间没有登录个人中心，如忘记登录密码，可咨询在线客服或拨打转400-6529-888，客服老师为您重置密码。
      </p>
      <br />
      <p>
        库课网客服接听电话时间：8：00-12：00，13：30-17：30，其他时间来电，问题若无法解决，请谅解！
      </p>
      <p>客服电话：400-6529-888</p>
      <br />
      <b>
        <p>•看课缓冲怎么办</p>
        <br />
        <p>课程时断时续或是长时间卡住，怎么办?</p>
      </b>
      <p>
        答：在观看课程的时候，建议使用的浏览器：如360浏览器和谷歌、火狐等。如果出现缓冲的情况，请先设置下课程的清晰度，课程右下角选择“标清”方式观看。(如下图)如果以上两种方式仍不能观看，请参照以下方法解决：
      </p>
      <br />
      <b>发生断课情况，有可能的几种原因：</b>
      <p>
        (1)网速比较慢。如果您在听课时，听了有半分钟以上才出现中断，而这个时候浏览器右上角的图标处于活动状态。一般是用户网络不稳定造成的。如果是ADSL宽带用户，请用户联系接入商，让接入商查查是否设备或设备周边是否有干扰，线路是否有干扰。
      </p>
      <p>
        (2)系统保留临时网络文件太多。请从IE的菜单工具-&gt;internet选项-&gt;常规中删除internet临时文件，清理缓存。
      </p>
      <p>
        (3)可能是计算机资源不足。如果您发现您在听免费课程时基本没有问题，但是在听收费课程时，听了一两句话就中断了，而且右上角图标也处于活动状态，可就是不能继续听课了。建议您加内存。
      </p>
      <p>
        建议使用win98内存至少在128M以上，磁盘空余不少100MB。如果有可选择的余地，请换台配置比较高的机器试试。如果还不能解决，请采用上述第一种解决办法。
      </p>
      <br />
      <b>课程出现黑屏或者缓冲问题该怎么解决?</b>
      <p>
        如果出现黑屏，请把您安装的FLASH插件更新，请直接点击课程下方的更新插件就可以，如果您没有安装，请按照提示点击下载安装即可，或是您可以直接到官方网站另行下载的，下载地址：http://get.adobe.com/cn/flashplayer/otherversions/，安装时请关闭其他网页。
      </p>
      <br />
      <p>如果更新插件后仍出现黑屏，分为两种情况：</p>
      <br />
      <p>
        第一，黑屏之后显示error2048。此种情况最大可能是电脑本身有问题。处理方法：首先清理电脑缓存文件;之后更新本地播放器插件，重启电脑。如果这两种方法都不能解决，请测试学员所在地的网络情况。测试方法为：点击“开始”，找“运行”，输入“cmd”之后点击确定，出现一个黑色的屏幕。在图中光标闪动处输入：ping
        www.163.com
        –t,之后点击回车。回车后如果显示的是一连串的数据信息，并且显示时间间隔不到一秒，说明您当地的网络没有问题。
      </p>
      <p>
        如果出现：time
        out，说明您当地的网络出现问题，请联系当地网络技术服务商为您调试。
      </p>
      <br />
      <p>
        第二，出现error2032，这种情况一般是课件本身的问题，请联系库课网校售后服务反映情况。
      </p>
      <p>学员听课时如出现课程一直缓冲，请先用360安全卫士清理电脑缓存文件;</p>
      <br />
      <b>在听课过程中，出现页面提示"听课请求异常，用户在线信息与请求异常"</b>
      <p>
        答：此情况是您在听课时断线重新连接后造成的。请重新登陆即可。如果您多次出现类似情况且使用宽带上网，请在上班时间与客服人员联系。
      </p>
      <p>
        库课网客服接听电话时间：8：00-12：00，13：30-17：30，其他时间来电，问题若无法解决，请谅解！
      </p>
      <p>客服电话：400-6529-888</p>
      <br />
      <b>
        <p>•在线到哪里提问</p>
        <br />
        <p>如果遇到专业性问题及课程资料问题到哪里提问?</p>
      </b>
      <p>
        一、学员在学习中遇到有关于讲义、课程类问题不清楚的地方，可以在购课后添加的答疑QQ群下里提问。
      </p>
      <p>
        二、学员登录库课官网，用户登录后，点击观看课程，在课程下方“学员评价”中提问，老师看见后会即可解答相关问题。步骤如下：
      </p>
      <p>
        1、登录库课网首页：<b
          ><a href="http://www.kuke99.com">http://www.kuke99.com</a></b
        >，在页面右侧“用户登陆”输入正确用户名、密码及验证码。
      </p>
      <p>
        2、进入“学习中心” → “我的课程” → “开始听课” →
        “查看详情”，在“学员评价”页面填写需要提出的问题。
      </p>
      <p>
        3、问题填写清楚后，点击“发布”老师看见后会即可和您取得联系，会耐心解答您的问题。
      </p>
      <p>
        三、
        学员在学习中遇到有问题不清楚的地方，可以在官网首页右边点击“客服”，咨询客服提问，老师会根据学员所报班次的优先级顺序和提问时间进行答复，一般会在24小时内回复。
      </p>
      <br />
      <p>如果您多次提问没有解决，请在上班时间与客服人员联系。</p>
      <br />
      <p>
        库课网客服接听电话时间：8：00-12：00，13：30-17：30，其他时间来电，问题若无法解决，请谅解！
      </p>
      <p>客服电话：400-6529-888</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
h6 {
  margin-top: 0;
}
p {
  margin-top: 0;
  margin-bottom: 0;
}
.article {
  width: 100%;
  padding: 0 0.3rem 0.4rem;
  box-sizing: border-box;
  font-size: 0.4rem;
  line-height: 0.6rem;
  color: #494949;
}
table {
  width: 100%;
  border: 1px solid #262626;
  margin: 0 auto;
  font-size: 0.2rem;
  color: #262626;
  border-collapse: collapse;
  border-spacing: 0;
}
table td {
  padding: 0 0.1rem;
  border: 1px solid #262626;
  line-height: 0.5rem;
}
</style>
>
